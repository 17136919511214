import { forwardRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { useBox } from '@react-three/cannon'

useGLTF.preload('/Car.glb')

// Auto-generated by: https://github.com/pmndrs/gltfjsx
// https://market.pmnd.rs/model/race-futrure
const Car = forwardRef(({ args = [1.7, 1, 4], mass = 500,children, ...props }, ref) => {
  const { nodes, materials } = useGLTF('/Car.glb')
  const [, api] = useBox(() => ({ mass, args, allowSleep: false //, onCollide: (e) => console.log('bonk', e.body.userData)
  , ...props }), ref)
  return (
    <mesh ref={ref} api={api}>
       <group position={[0, -0.6, 0.4] } scale={[1.5,1.5,1.5]}>
       <mesh geometry={nodes.Mesh_body015.geometry} material={materials.plastic} />
      <mesh geometry={nodes.Mesh_body015_1.geometry} material={materials.paintBlue} />
      <mesh geometry={nodes.Mesh_body015_2.geometry} material={materials._defaultMat} />
      <mesh geometry={nodes.Mesh_body015_3.geometry} material={materials.window} />
    {/* </group> */}
      {/* {console.log(childc)} */}
      {children}
      </group>
    </mesh>
  )
})

export default Car
